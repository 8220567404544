import React, {useContext} from 'react';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import GlobalStateContext from "../../store/GlobalStateContext";

import {default as BackBtn} from '../../Components/Buttons/Back';
import {default as TimeTableBtn} from '../../Components/Buttons/TimeTable';
import RealtimeResults from "../../Components/Buttons/RealtimeResults";
import DateFormater from "../../Utils/DateFormater";
import useResultsAndTimetableChecker from "../../Utils/useResultsAndTimetableChecker";

const Detail = (props) => {
    const { globalState } = useContext(GlobalStateContext);
    const { calendar } = globalState.data;
    const currentEvent = calendar[props.match.params.id];
    const resultsAndTimetables = useResultsAndTimetableChecker(calendar);
    const _resultsAndTimetable = currentEvent.event ? resultsAndTimetables[currentEvent.event.uuid] : undefined;

    const EventMap = withGoogleMap(props => {
        return <GoogleMap defaultZoom={17} defaultCenter={{ lat: currentEvent.location.latitude, lng: currentEvent.location.longitude }}>
            <Marker position={{ lat: currentEvent.location.latitude, lng: currentEvent.location.longitude }} />
        </GoogleMap>
    });

    const isRealtime = () => {
        if (null !== props.event && undefined !== props.event) {
            return undefined !== globalState.data.currentEvents[props.event.uuid];
        }

        return false;
    }

    return <div className="page--calendar-detail">
        <header>
            <BackBtn />

            <span id="date">{ DateFormater.format(currentEvent.date) }</span>
            <h1>{currentEvent.title}</h1>
            <div className="btn-group--vertical">
                { currentEvent.event ? <TimeTableBtn event={currentEvent.event} visible={undefined !== _resultsAndTimetable && true === _resultsAndTimetable.timetable} /> : <></> }
                { currentEvent.event ? <RealtimeResults event={currentEvent.event} realtime={isRealtime()} visible={undefined !== _resultsAndTimetable && true === _resultsAndTimetable.results} /> : <></> }
            </div>
            <h2>{ currentEvent.subTitle }</h2>
        </header>

        <div className="container--event-info">
            { (currentEvent.location.latitude && currentEvent.location.longitude) ?
                <EventMap
                    containerElement={<div style={{ height: `250px`, marginBottom: 30 }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                /> : <></> }
            <div style={{whiteSpace: "pre-wrap"}}>
                { currentEvent.description }
            </div>
        </div>
    </div>
};

export default Detail;
import React from 'react';

const ResultsTableFinal = (props) => {
    const renderPosition = (pos, qualified) => {
        if ("string" === typeof qualified) {
            switch(qualified) {
                case "DNS": return <span style={{fontSize: "16px", color: "#000000"}}>DNS</span>;
                case "DNF": return <span style={{fontSize: "16px", color: "#000000"}}>DNF</span>;
            }
        }

        return pos;
    }

    return <table className={"results-table results-table--classic"}>
        <thead>
            <tr>
                <th className={"results-table__column results-table__column--pos"} />
                <th className={"results-table__column results-table__column--stn"} />
                <th className={"results-table__column results-table__column--school"}>Kolektiv</th>
                <th className={"results-table__column results-table__column--competitor"}>Choreografie</th>
            </tr>
        </thead>
        <tbody>
        { props.results.map((result, key) => {
            return <tr key={key}>
                <td className={"results-table__column results-table__column--pos"}>{renderPosition(result.pos, result.qualified)}</td>
                <td className={"results-table__column results-table__column--stn"}>{result.stn}</td>
                <td className={"results-table__column results-table__column--school"}>{result.school}</td>
                <td className={"results-table__column results-table__column--competitor"}>{result.competitor}</td>
            </tr>
        }) }
        </tbody>
    </table>;
}

export default ResultsTableFinal;